import React, {useState} from "react"
import { Button, Form} from 'react-bootstrap';

// import toastGlobal
import { ToastContainer } from 'react-toastify';
import login from '../FrmLogin/actions/login';

const FrmLogin = () => {

    //global consts
    const [user, setUser]  = useState('');
    const [password, setPassword]  = useState('');



    return(
        <>
        <ToastContainer />
        <Form onSubmit={(e)=>{
            const json = {
                user: user,
                password: password,
            };
            login.login(e,json);
        }} >
            <Form.Group className="mb-3" controlId="user">
                <Form.Label>Usuario</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Ejem: JoseRamirez" 
                    onChange={(e)=>{
                        setUser(e.target.value)
                    }}
                />
                <Form.Text className="text-muted">
                    Ingresa el usuario asignado sin espacios.
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control 
                    type="password" 
                    placeholder="Ejem: Natividad2021_" 
                    onChange={(e)=>{
                        setPassword(e.target.value)
                    }}
                />
                <Form.Text className="text-muted">
                    Ingresa la contraseña asignada sin espacios.
                </Form.Text>
            </Form.Group>
            <div className='d-grid gap-2 pt-3'>
                <Button variant="success" type="submit" className="btn-block">
                    Iniciar sesión
                </Button>
            </div>
        </Form>
        </>
    )
}

export default FrmLogin


