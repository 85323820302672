import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Container, Row,Col, Card} from 'react-bootstrap'
// import components
import FrmLogin from '../components/Forms/FrmLogin'


const login = () => (
  <Layout>
    <Seo title="Inicio de sesión" />
    <Container>
        <Row style={{"height": "500px"}}>
            <Col md={3}></Col>
            <Col md={6} className="mt-4 pt-4">
                <Card>
                    <Card.Header>Inicio de sesión</Card.Header>
                    <Card.Body>
                        <FrmLogin />        
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    
    </Container>
  </Layout>
)

export default login
