
import toastMsg from '../../../Toast';
import {auth, store} from '../../../Firebase/firebase-config';
import {navigate} from "gatsby"

const login = {};



login.login = async (e, json)=>{
    e.preventDefault();
    if(!json.user.trim()){
        toastMsg.errorToast("El usuario esta vacio");
        return;
    }
    if(!json.password.trim()){
        toastMsg.errorToast("La contraseña esta vacio");
        return;
    }
    try {
        await auth.signInWithEmailAndPassword(json.user,json.password)
        .then(async (res)=> {
            await store.collection('roles').doc(res.user.uid).get()
            .then(async (doc)=>{
                const attempts = doc.data().attempts;
                console.log(attempts);
                if(attempts <=0){
                    toastMsg.errorToast("No tienes acceso contacta con tu proveedor");
                }else{
                    await store.collection('roles').doc(res.user.uid).update({
                        attempts: (attempts-1)
                    })
                    .then(()=>{
                        localStorage.setItem('user',res.user.uid);
                        navigate('/menu');
                        
                    })
                }
            });
            // console.log(data.data);
            // navigate('/prueba-firestore');
        })
        .catch((e)=>{
            if(e.code === 'auth/user-not-found'){
                toastMsg.errorToast('autenticacion fallida')
            }
            if(e.code === 'auth/wrong-password'){
                toastMsg.errorToast('Password incorrecta')
            }
        })
    } catch (error) {
        alert(error)
    }
};


export default login;